//import Cookies from 'js-cookie';
import Hashids from 'hashids';
//import axios  from 'axios';
const hashids = new Hashids('abc789', 10);
export default {

    data() {
        return {
            DefaultLanguage:null, 
            firstViewData: {}
        };
      },

    computed: {
        baseUrl() {            
            // Extract the main domain and prepend 'admin.' subdomain
            // const { hostname } = window.location;
            // const parts = hostname.split('.');
            // let domain = '';
      
            // if (parts.length >= 2) {
            //   domain = parts.slice(-2).join('.'); // Get the last two parts (main domain)
            // }
      
            // return `${window.location.protocol}//admin-stage.${domain}/`;
            // Replace this with your actual base URL
            return 'https://admin.yourasianshop.com/';  

            //https://admin.yourasianshop.com/     
            //https://admin-stage.emorebuymore.lk/http://127.0.0.1:8000/////admin-stage-mve-demo1.techquestglobal.com, https://admin.yourasianshop.com/
        },
        baseFrontUrl() {
            // Replace this with your actual base URL
           
           // return `${window.location.protocol}//${window.location.hostname}/`;
            return 'https://yourasianshop.com/';
            //https://stage.emorebuymore.lk/http://localhost:8080 // https://mve-demo1.techquestglobal.com // https://yourasianshop.com
        },
        limit(){
            return 20;
        },
        default_userImage(){
            return require('@/assets/images/user_avatar.jpeg');
        },
        default_logoImage(){
            return require('@/assets/images/logo_avatar.jpg');
        },
        paginationItemCount(){
            return 15;
        },
        expDay(){
            return 1;
        },
        captchaSiteKey(){
            //6LcxXjsqAAAAAGIQqPLZWt6QnCqdAehoB0qK_69L
            return '6LcxXjsqAAAAAGIQqPLZWt6QnCqdAehoB0qK_69L';
        },
        baseAdminUrl() {
            // Extract the main domain and prepend 'admin.' subdomain
            const { hostname } = window.location;
            const parts = hostname.split('.');
            let domain = '';
      
            if (parts.length >= 2) {
              domain = parts.slice(-2).join('.'); // Get the last two parts (main domain)
            }
      
            return `${window.location.protocol}//admin-stage.${domain}/`;
          },
    },

    methods: {
        clearCookiesAndLocalStorage() {
            // Clear localStorage
            localStorage.clear();
        
            // Clear cookies
            // document.cookie.split(";").forEach((cookie) => {
            //     document.cookie = cookie
            //         .replace(/^ +/, "")
            //         .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
            // });
        },
        setItemWithExpirationCommon(key, value, expirationInDays) {
            const now = new Date();

            // Add expiration time in milliseconds (6 days)
            const expirationTime = now.getTime() + expirationInDays * 24 * 60 * 60 * 1000;

            // Store the value and expiration time
            const item = {
                value: value,
                expiration: expirationTime,
            };

            localStorage.setItem(key, JSON.stringify(item));
        },
        getItemWithExpirationCommon(key) {
            const itemStr = localStorage.getItem(key);

            // If the item doesn't exist, return null
            if (!itemStr) {
                return null;
            }

            const item = JSON.parse(itemStr);
            const now = new Date();

            // Compare the expiration time with the current time
            if (now.getTime() > item.expiration) {
                // Item has expired, remove it from localStorage
                localStorage.removeItem(key);
                return null;
            }

            return item.value;
        },
        encodeUrlParams(value) {
     
            // Encode the numbers into hash
            const hashedId = hashids.encode(value);
      
            // Optionally, log the new URL
          //  console.log('New hashed URL:', hashedId);
            return hashedId;
          },
          decodeUrlParams(value){
            const decodedId = hashids.decode(value)[0];
            return decodedId;
          },
       
        // setStoreCookieData(key, value, expirationInDays){
        //     Cookies.set(key, value, { expires: 6 });
        // },
        // getStoreCookieData(key){
           
        // }
    },
    created() {
        // Retrieve data from session storage
        const storedData = sessionStorage.getItem('firstViewData');
        if (storedData) {
          this.firstViewData = JSON.parse(storedData); // Parse the JSON string
        }
    }
   
}; 