<template>
   <div class="modal location-modal fade theme-modal" id="MobileSearchModalDisplay" tabindex="-1"
        aria-labelledby="MobileSearchModalDisplay" aria-hidden="true" ref="locationselectContainer">
        <div class="modal-dialog modal-dialog-centered modal-fullscreen-sm-down">
            <div class="modal-content container-fluid">
                <div class="modal-header">
                    <h5 class="modal-title" id="MobileSearchModalDisplay">Search your Products or Categories</h5>
                    <p class="mt-1 text-content">Enter you search products and categories here.</p>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <i class="fa-solid fa-xmark" style="margin-left: 0px;"></i>
                    </button>
                </div>
                
                <div class="modal-body">
                    <div class="search-box">
                        <div class="category-dropdown container">
                            <div class="category-title">
                                <form method="post" ref="SearchForm" @submit.prevent="SearchSubmit">
                                    <div class="input-group">
                                        <input type="search" class="form-control" :placeholder="searchPlaceholder" v-model="searchQuery"
                                            name="searchValue"
                                            aria-label="Recipient's username"
                                            aria-describedby="button-addon2"
                                            @focus="showDropdown = true"
                                            @blur="hideDropdown"
                                        />
                                        <button class="btn" type="submit" id="button-addon2" aria-label="search">
                                            <i data-feather="search"></i>
                                        </button>
                                    </div>
                                </form>
                                
                            </div>
                            <div class="dropdown-menus2 col-md-5 container-fluid" v-if="showDropdown && filteredItems.length > 0" @mousedown.prevent>
                                <div class="row align-items-center mb-3">
                                    <div class="col d-flex justify-content-end">
                                        <a class="text-capitalize link-primary" @click="clearallHistory">Clear all</a>
                                        
                                    </div>
                                </div>
                                <div> 
                                    <a class="dropdown-items mt-1 mb-1" v-for="data in filteredItems" :key="data.name" @click="RedirectShopPage(data)">
                                        {{ data.name }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import { RouterLink } from "vue-router";
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
    components: {
       // RouterLink
    },
    name: 'header-mobilesearch-nav',
    data() {
        return {
            timer: null,
            fullPage: false,
            loginStatus:null,
            searchQuery: '',
            dropdownVisible: false,
            dropdown2Visible: false,
           
            categoryData: [],
            subcategoryData: [],
            searchHistory:[],
            searchPlaceholder:'I am Searching for ....',
            activeItemId:null,
            language:null,
            showDropdown: false,
            CombineArray:[],
            filteredItems:[],
            SearchAllData:[]
        };
    },
    props: {

    },
    computed: {
        
    },
    

    setup() {

        return {
           

        };
    },
    methods: {
        hideDropdown() {
            // Delay hiding the dropdown to allow click event to register
            setTimeout(() => {
                this.showDropdown = false;
            }, 200);
        },

        handleRouterLinkClick(dealName) {
            let routeName = 'DealShopPage';

            this.$router.push({
                name: routeName,
                params: { dealName: dealName },
            })
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
        },
        RedirectShopPage(data) {
           
            this.searchPlaceholder = data.name;
            
            if(localStorage.getItem('lastSearchCategory')){
                localStorage.removeItem('lastSearchCategory');
            }
            localStorage.setItem('lastSearchCategory', data.name);

             // Check if the provided category_id already exists in the array
            if (!this.searchHistory.some(item => item.name === data.name)) {
                // Push new data into the array
                this.searchHistory.push({ categoryIds: data.categoryIds, name: data.name });

                // Store the updated array in localStorage
                localStorage.setItem('categorySearchHistory', JSON.stringify(this.searchHistory));
            }
            this.$router.push({ name: 'SearchShopPage', params: { CategoryId: data.categoryIds }, query:{name : data.name}})
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
        },
        
        async fetchCategoryData(language) {
            try {
            const response = await axios.get(`home/searchindexdata?language=${language}`);
            this.categoryData = response.data;
            this.SearchAllData = [...this.categoryData, ...this.searchHistory];

            this.SearchAllData = this.SearchAllData.reduce((acc, item) => {
                    if (!acc.find(i => i.name === item.name)) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
            } catch (error) {
            console.error('Error fetching Category data:', error);
            }
        },

        toggleDropdown() {
             this.dropdownVisible = !this.dropdownVisible;
        },
              
        clearallHistory(){
            localStorage.removeItem('categorySearchHistory');
            localStorage.removeItem('lastSearchCategory');
            this.searchPlaceholder = 'I am searching for ....'
            
            this.CombineArray = [];
            this.SearchAllData = this.categoryData;
        },
        SearchSubmit(){
            
            var ArrayNew = [];
            ArrayNew = this.SearchAllData.filter(item =>
                        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    );

            const uniqueItems = ArrayNew.reduce((acc, item) => {
                if (!acc.find(i => i.name === item.name)) {
                    acc.push(item);
                }
                return acc;
            }, []);

            // Step 1: Extract all categoryIds
                let allCategoryIds = uniqueItems.map(item => item.categoryIds);

            // Step 2: Split each categoryId string by commas
            let splitCategoryIds = allCategoryIds.flatMap(ids => ids.split(','));

            // Step 3: Convert to Set to remove duplicates, then back to array
            let uniqueCategoryIds = [...new Set(splitCategoryIds)];
            let finalCategoryIds = 0;
            if(uniqueCategoryIds.length > 0){
                // Step 4: Join the unique ids into a single string
                finalCategoryIds = uniqueCategoryIds.join(',');
            }


            if(localStorage.getItem('lastSearchCategory')){
                localStorage.removeItem('lastSearchCategory');
            }
            localStorage.setItem('lastSearchCategory', this.searchQuery);

             // Check if the provided category_id already exists in the array
            if (!this.searchHistory.some(item => item.name === this.searchQuery)) {
                // Push new data into the array
                this.searchHistory.push({ categoryIds: finalCategoryIds, name: this.searchQuery });

                // Store the updated array in localStorage
                localStorage.setItem('categorySearchHistory', JSON.stringify(this.searchHistory));
            }
            // console.log('history',this.searchHistory);
            this.$router.push({ name: 'SearchShopPage', params: { CategoryId: finalCategoryIds }, query:{name : this.searchQuery}})
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });

        }
       
    },
    watch: {
        searchQuery(val){
            if(val == null){
                
                this.filteredItems = this.CombineArray;
            }else{
                var Array = [];
                Array = this.categoryData.filter(item =>
                        item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
                    );

                    const uniqueItems = Array.reduce((acc, item) => {
                        if (!acc.find(i => i.name === item.name)) {
                            acc.push(item);
                        }
                        return acc;
                    }, []);

                    this.filteredItems = uniqueItems;  
            }
        }
    },

    mounted() {
        
        if(Cookies.get("CookiesLanguageCode")){
        this.language = Cookies.get("CookiesLanguageCode"); 
       }else{
        this.language = 'en';
       }

       this.fetchCategoryData(this.language);
       
        this.searchQuery = null;
        if(localStorage.getItem('lastSearchCategory')){
            this.searchPlaceholder = localStorage.getItem('lastSearchCategory');
        }

       

    },
    created(){
        let searchCategoryData = localStorage.getItem('categorySearchHistory');
        if(searchCategoryData){
            this.searchHistory = JSON.parse(searchCategoryData);
        }
         this.CombineArray = this.searchHistory;
        
    }

}
</script>


<style scoped>
.location-modal .modal-dialog .modal-content .modal-body .location-list .disabled-box{
    margin-top:-15px
}

header .top-nav .navbar-top .middle-box .location-box button i {
    margin-left: 3px;
    color: var(--themecolor);
}

header .location-box button .locat-name {
    font-size: 13px;
    font-weight: 600;
    color: var(--theme-color);
}

.dropdown-items{
    display: block;
    text-align: justify;
}
</style>