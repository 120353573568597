import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/test',
            name: 'TestContent',
            component: () => import('../ProductPage/ProductleftSidebar/testFile.vue'),
            props: true
           
        },
        {
            path: '/staticpage',
            name: 'StaticPage',
            component: () => import('../StaticPage/StaticPage.vue'),
            props: true
           
        },
        {
            path: '/testmollie',
            name: 'TestMollie',
            component: () => import('../CheckoutContent/PaymentMolliePage.vue'),
            props: true
           
        },
        {
            path: '/Personalized-Gifts',
            name: 'Customizeproducts',
            component: () => import('../ShopPage/AllShop/CustomizedProductShopIndex.vue'),
            props: true
           
        },
        {
            path: '/newarrivals',
            name: 'Newarrivals',
            component: () => import('../ShopPage/AllShop/NewArrivals.vue'),
            props: true
           
        },
        {
            path: '/bestsellers',
            name: 'Bestsellers',
            component: () => import('../ShopPage/AllShop/BestSellers.vue'),
            props: true
           
        },
        {
            path: '/customdetail/:id',
            name: 'CustomizeproductsDetails',
            component: () => import('../Custompage/ProductPage/indexPage.vue'),
            props: true
           
        },
        {
            path: '/compareproductpage/:productId',
            name: 'Compareproductpage',
            component: () => import('../CompareProductpage/indexPage.vue'),
            props: route => ({
                productId: route.params.productId,
                variationId: route.query.variationId,
                compare_productId: route.query.compare_productId,
                compare_variationId: route.query.compare_variationId,
            }),
        },
        {
            path: '/combodetail/:id',
            name: 'CombodealproductsDetails',
            component: () => import('../CombodealProductPage/indexPage.vue'),
            props: true
           
        },
        {
            path: '/',
            name: 'HomeContent',
            component: () => import('../HomeContent/HomePage.vue'),
            props: true
           
        },
        {
            path: '/shop',
            name: 'Shop',
            children: [
                {
                    path: 'Allshop',
                    name: 'AllShop',
                    component: () => import('../ShopPage/AllShop/AllShopIndex.vue'),
                    props: true,
                    
                },
                {
                    path: 'Welcomedeal',
                    name: 'welcomedealsShopPage',
                    component: () => import('../ShopPage/WelcomeDealPage/WelcomeDealIndex.vue'),
                    props: true,
                },
                {
                    path: ':CategoryId',
                    name: 'CategoryShopPage',
                    component: () => import('../ShopPage/AllShop/AllShopIndex.vue'),
                    props: true,
                },
                {
                    path: 'Search/:CategoryId',
                    name: 'SearchShopPage',
                    component: () => import('../ShopPage/AllShop/AllShopSearchIndex.vue'),
                    props: true,
                },
                {
                    path: 'Deal/:dealName',
                    name: 'DealShopPage',
                    component: () => import('../ShopPage/AllShop/AllDealShopIndex.vue'),
                    props: true,
                },
                {
                    path: 'Wishlist',
                    name: 'wishlistShopPage',
                    component: () => import('../ShopPage/AllShop/WishlistShopIndex.vue'),
                     props: true,
                },
                {
                    path: 'Sellershop/:id',
                    name: 'sellerShopPage',
                    component: () => import('../ShopPage/AllShop/SellerShopIndex.vue'),
                     props: true,
                },
                {
                    path: 'Sellerpage/:id',
                    name: 'newsellerShopPage',
                    component: () => import('../ShopPage/AllShop/NewSellerShopIndex.vue'),
                     props: true,
                },
                {
                path: 'Storepage/:id',
                name: 'storeShopPage',
                component: () => import('../ShopPage/AllShop/StoreShopIndex.vue'),
                 props: true,
                },
                {
                    path: 'Combodealshop',
                    name: 'combodealShopPage',
                    component: () => import('../ShopPage/AllShop/CombodealShopIndex.vue'),
                     props: true,
                },
                {
                    path: 'CategoryDeal/:id',
                    name: 'CategoryDealShopPage',
                    component: () => import('../ShopPage/AllShop/CategoryDealShopIndex.vue'),
                    props: true,
                },
                {
                    path: 'AllProductShoppage',
                    name: 'ProductShop',
                    component: () => import('../ShopPage/AllShop/AllProductShopIndex.vue'),
                    props: true,
                },
                {
                    path: 'OtherDeal/:dealName',
                    name: 'OtherDealShopPage',
                    component: () => import('../ShopPage/AllShop/OtherDealShopIndex.vue'),
                    props: route => ({
                        dealName: route.params.dealName,
                        dealId: route.query.deal_id
                    }),
                },
            ]
        },
        
        {
            path: '/cart',
            name: 'Cart',
            component: () => import('../CartContent/CartPage.vue'),
            props: true
        },
        {
            path: '/mobile-search',
            name: 'MobileSearch',
            component: () => import('../MobileSearch/MobileSearchPage.vue'),
            props: true
        },
        {
            path: '/mobile-category',
            name: 'MobileCategory',
            component: () => import('../MobileCategoryDisplay/MobileCategoryPage.vue'),
            props: true
        },
        {
            path: '/checkout',
            name: 'Checkout',
            component: () => import('../CheckoutContent/CheckoutPage.vue'),
            props: true
        },
        {
            path: '/order-success',
            name: 'PaymentSuccess',
            component: () => import('../CheckoutContent/PaymentSuccessPage.vue'),
            props: true
        },
        {
            path: '/payment-success',
            name: 'PaymentSuccessInCard',
            component: () => import('../CheckoutContent/PaymentSuccessPageCard.vue'),
            props: true
        },
        {
            path: '/payment-faild',
            name: 'PaymentFaildInCard',
            component: () => import('../CheckoutContent/PaymentFaildPageCard.vue'),
            props: true
        },
        {
            path: '/cardpayment-success/:orderId',
            name: 'CardPaymentSuccess',
            component: () => import('../CheckoutContent/CardPaymentSuccessPage.vue'),
            props: true
        },
        {
            path: '/order-faild',
            name: 'PaymentFaild',
            component: () => import('../CheckoutContent/PaymentFaildPage.vue'),
            props: true
        },
        {
            path: '/Cardpayment-faild/:orderId',
            name: 'PaymentFaild',
            component: () => import('../CheckoutContent/CardPaymentFaildPage.vue'),
            props: true
        },
        {
            path: '/payment',
            name: 'Payment',
            component: () => import('../CheckoutContent/PaymentPage.vue'),
            props: true
        },
        {
            path: '/product/:id',
            name: 'DetailView',
            component: () => import('../ProductPage/indexPage.vue'),
            props: route => ({ id: route.params.id, queryParam: route.query.VariationId })
        },
        {
            path: '/wishlist',
            name: 'Wishlist',
            component: () => import('../WishlistContent/WishlistPage.vue'),
            props: true
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('../RegisterContent/RegisterPage.vue'),
            props: true
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../LoginSection/LoginPage.vue'),
            props: true
        },
        {
            path: '/forgot',
            name: 'forgot',
            component: () => import('../ForgotContent/ForgotPage.vue'),
            props: true
        },

        {
            path: '/customer',
            name: 'CustomerAccount',
            children: [
                {
                    path: ':id',
                    name: 'CustomerAccountActive',
                    component: () => import('../CustomerAccount/AccountActive.vue'),
                    props: true,
                    
                },
                {
                    path: 'resendaccountactive',
                    name: 'CustomerAccountReActive',
                    component: () => import('../CustomerAccount/ResendAccountActive.vue'),
                    props: true,
                    
                },
                {
                    path: 'forgotpassword',
                    name: 'CustomerForgotpassword',
                    component: () => import('../CustomerAccount/AccountforgotPassword.vue'),
                    props: true,
                    
                },
                {
                    path: 'forgotpassword/:id',
                    name: 'CustomerForgotpasswordreset',
                    component: () => import('../CustomerAccount/AccountforgotPasswordreset.vue'),
                    props: true,
                    
                },
            ]
        },

        {
            path: '/customerdashboard',
            name: 'CustomerAccountDashboard',
            children: [
                {
                    path: ':id',
                    name: 'CustomerAccountDashboardHome',
                    component: () => import('../CustomerDashboard/DashboardSection.vue'),
                    props: true,
                    
                },
                
            ]
        },
       
    ]
})
export default router