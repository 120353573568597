<template>
  <div class="dropdown">
    <button type="button" class="btn dropdown-category" data-bs-toggle="dropdown" aria-expanded="false" aria-label="All Categories">
      <i data-feather="align-left"></i><span>{{ $t("allcategories") }}</span>
    </button>

    

    <ul class="dropdown-menu category-list menu1">
      <li class="nav-item dropend onhover-category-list mb-3" v-for="category in CategoryData" :key="category.id">
        <a @click="clicktoGocategoryshopPage(category.id)"
          @mouseover="handleMouseHovercategory(category.id, category.name)"
          class="category-name row"
          
        >
          <div class="col-md-3 col-sm-4" ><img :src="baseUrl + category.image" :alt="category.name" v-if="category.image"></div>
          <div class="col-md-6 col-sm-6" style="text-align: justify;"><h4>{{ category.name }} </h4></div>
          <div class="col-md-1 col-sm-2" v-if="category.child_status == 1"><i class="fa-solid fa-angle-right"></i></div>
      </a>

        <ul class="dropdown-menu" v-if="SubcategoryData.length > 0">
          <div class="category-title-box text-muted text-center mb-3">
            <h5>{{ firstLevelCategoryName }}</h5>
          </div>

          <li
            class="nav-item dropend onhover-category-list mb-3"
            v-for="subcategory in SubcategoryData"
            :key="subcategory.id"
          >
            <a @click="clicktoGocategoryshopPage(subcategory.id)"
              @mouseover="handleFirstMouseHovercategory(subcategory.id, subcategory.name)"
              class="category-name row"
             
            >
          
              <div class="col-md-3"><img :src="baseUrl + subcategory.image" :alt="subcategory.name" v-if="subcategory.image"></div>
              <div class="col-md-6" style="text-align: left;"><h4>{{ subcategory.name }} </h4></div>
              <div class="col-md-1" v-if="subcategory.child_status == 1"><i class="fa-solid fa-angle-right"></i></div>
        </a>

            <ul class="dropdown-menu" v-if="SecondSubcategoryData.length > 0">
              <div class="category-title-box text-muted text-center mb-3">
                <h5>{{ secondLevelCategoryName }}s</h5>
              </div>
              <li
                class="nav-item dropend onhover-category-list mb-3"
                v-for="secondSubcategory in SecondSubcategoryData"
                :key="secondSubcategory.id"
              >
                <a @click="clicktoGocategoryshopPage(secondSubcategory.id)"
                  @mouseover="handleSecondMouseHovercategory(secondSubcategory.id, secondSubcategory.name)"
                  class="category-name row"
                 
                >
                  <div class="col-md-3"><img :src="baseUrl + secondSubcategory.image" :alt="secondSubcategory.name" v-if="secondSubcategory.image"></div>
                  <div class="col-md-6" style="text-align: justify;"><h4>{{ secondSubcategory.name }} </h4></div>
                  <div class="col-md-1" v-if="secondSubcategory.child_status == 1"><i class="fa-solid fa-angle-right"></i></div>
            </a>

                <ul class="dropdown-menu" v-if="ThirdSubcategoryData.length > 0">
                  <div class="category-title-box text-muted text-center mb-3">
                    <h5>{{ thirdLevelCategoryName }}</h5>
                  </div>
                  <li class="nav-item onhover-category-list mb-3" v-for="thirdSubcategory in ThirdSubcategoryData" :key="thirdSubcategory.id">
                    <a @click="clicktoGocategoryshopPage(thirdSubcategory.id)" class="category-name row">
                      <div class="col-md-3"><img :src="baseUrl + thirdSubcategory.image" :alt="thirdSubcategory.name" v-if="thirdSubcategory.image"></div>
                      <div class="col-md-6" style="text-align: justify;"><h4>{{ thirdSubcategory.name }} </h4></div>
                      <div class="col-md-1" v-if="thirdSubcategory.child_status == 1"><i class="fa-solid fa-angle-right"></i></div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>

      
  </div>

  <button class="d-xl-none d-inline navbar-menu-button" type="button" data-bs-toggle="offcanvas" data-bs-target="#primaryCategoryMenu" aria-label="All Categories">
    <i data-feather="align-left"></i><span>{{ $t("allcategories") }}</span>
    </button>

    <div class="offcanvas offcanvas-collapse order-xl-2" id="primaryCategoryMenu">
          <div class="offcanvas-header navbar-shadow">
              <h5>Categories</h5>
              <button class="btn-close lead" type="button" data-bs-dismiss="offcanvas"
                  aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <div class="accordion accordion-flush navbar-nav" id="accordionFlushExample">
              <div class="accordion-item nav-item" v-for="(category, index) in CategoryData" :key="category.id">
                <span class="accordion-header" :id="'flush-heading' + index">
                  <span class="accordion-title nav-link" @click="clicktoGocategoryshopPage(category.id)" style="width: 80%;">{{ category.name }}</span>
                  <button class="accordion-button collapsed" type="button" :data-bs-toggle="'collapse'" :data-bs-target="'#flush-collapse' + index" aria-expanded="false" :aria-controls="'flush-collapse' + index" style="width: 20%;" v-if="category.child_status == 1">
                  </button>
                </span>
                <div :id="'flush-collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body">
                    <div class="accordion accordion-flush navbar-nav" id="accordionExample">


                      <div class="accordion-item nav-item" v-for="(categoryall, ind) in AllCategoryData" :key="ind">
                        <span class="accordion-header" :id="'flush-heading' + categoryall.id" v-if="categoryall.parent_id == category.id">
                          <span class="accordion-title nav-link" @click="clicktoGocategoryshopPage(categoryall.id)" style="width: 80%;">{{ categoryall.name }}</span>
                          <button class="accordion-button collapsed" type="button" :data-bs-toggle="'collapse'" :data-bs-target="'#flush-collapse' + categoryall.id" aria-expanded="false" :aria-controls="'flush-collapse' + categoryall.id" style="width: 20%;" v-if="categoryall.child_status == 1">
                          </button>
                        </span>
                        <div :id="'flush-collapse' + categoryall.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + categoryall.id" data-bs-parent="#accordionExample" v-if="categoryall.parent_id == category.id">
                          <div class="accordion-body">
                            <ul class="navbar-nav">
                                <li class="nav-item dropdown" v-for="all in AllCategoryData" :key="all.id">
                                    <a class="nav-link home" @click="clicktoGocategoryshopPage(all.id)" v-if="all.parent_id == categoryall.id"> {{ all.name }}
                                    </a>
                                </li>   
                            </ul>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>


</template>

<script>
 document.querySelectorAll('.accordion-title').forEach(function(span) {
    span.addEventListener('click', function(event) {
      event.stopPropagation();
    });
  });

import axios from 'axios';
import Cookies from 'js-cookie';
// import { RouterLink } from 'vue-router';
export default {
  name: 'category-set',
  components: {  },
  data() {
    return {
      CategoryData: [],
      SubcategoryData: [],
      firstLevelCategoryName: null,
      secondLevelCategoryName: null,
      SecondSubcategoryData: [],
      thirdLevelCategoryName: null,
      ThirdSubcategoryData: [],
      currentHoveredCategory: null,
      language: null,
      AllCategoryData:[]
    };
  },
  methods: {
    async fetchCategorydata(language) {
      try {
        const response = await axios.get(`home/withoutparentcategorydata?language=${language}`);
        this.AllCategoryData = response.data; // Assuming response.data is an array of category names
        this.setLocalStorageWithExpiry('WithoutParentCategoryData', this.AllCategoryData, 1);
        
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    },
    async fetchParentCategorydata(language) {
      try {
        const response = await axios.get(`home/categorydata?language=${language}`);
        this.CategoryData = response.data; // Assuming response.data is an array of category names
        this.setLocalStorageWithExpiry('ParentCategoryData', this.CategoryData, 1);
        
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    },

    setLocalStorageWithExpiry(key, value, hours) {
      const now = new Date();
      const item = {
        value: value,
        expiry: now.getTime() + hours * 60 * 60 * 1000,
      };
      localStorage.setItem(key, JSON.stringify(item));
    },

    getLocalStorageWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      
      const item = JSON.parse(itemStr);
      const now = new Date();
      
      // Compare the expiry time with the current time
      if (now.getTime() > item.expiry) {
        // If the item is expired, remove it from localStorage and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },
    async handleMouseHovercategory(id, name) {
      this.currentHoveredCategory = id;
      this.firstLevelCategoryName = name;
      this.SubcategoryData = this.FilterCategoryIdbased(this.AllCategoryData,id);
      
    },

    FilterCategoryIdbased(Data,categoryId){
      const filteredCategoryData = Data.filter(category => category.parent_id == categoryId);

      return filteredCategoryData
    },
    async handleFirstMouseHovercategory(id, name) {
      this.currentHoveredCategory = id;
      this.secondLevelCategoryName = name;
      this.SecondSubcategoryData = this.FilterCategoryIdbased(this.AllCategoryData,id);
      
    },

    async handleSecondMouseHovercategory(id, name) {
      this.currentHoveredCategory = id;
      this.thirdLevelCategoryName = name;
      this.ThirdSubcategoryData = this.FilterCategoryIdbased(this.AllCategoryData,id);
    },

    handleMouseOut(id) {
      if (this.currentHoveredCategory === id) {
        this.currentHoveredCategory = null;
        this.resetSubcategoryData();
      }
    },

    resetSubcategoryData() {
      this.SubcategoryData = [];
      this.SecondSubcategoryData = [];
      this.ThirdSubcategoryData = [];
      this.firstLevelCategoryName = null;
      this.secondLevelCategoryName = null;
      this.thirdLevelCategoryName = null;
    },
    clicktoGocategoryshopPage(category_id){
            this.$router.push({
                name: 'CategoryShopPage',
                params: { CategoryId: category_id },
            })
            .catch(() => {})
            .finally(() => {
                // Reload the window
                window.location.reload();
            });
    }
  },
  mounted() {
    if (Cookies.get("CookiesLanguageCode")) {
      this.language = Cookies.get("CookiesLanguageCode");
    } else {
      this.language = 'en';
    }
    // this.fetchParentCategorydata(this.language);
    // this.fetchCategorydata(this.language);
    const storedCategoryData = this.getLocalStorageWithExpiry('ParentCategoryData');
      if(storedCategoryData){
        this.CategoryData = storedCategoryData;
      }else{
        this.fetchParentCategorydata(this.language);
      }

     const WithoutParentcategoryData = this.getLocalStorageWithExpiry('WithoutParentCategoryData');
    // this.fetchCategorydata(this.language);
    if (WithoutParentcategoryData) {
      this.AllCategoryData = WithoutParentcategoryData;
    }else{
      this.fetchCategorydata(this.language);
    }
  },
};
</script>

<style scoped>

.category-name{
  cursor: pointer;
}
.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
    width: 100%;
  }

  .accordion-title {
    flex: 0 0 80%;
    margin: 0;
  }

  .accordion-button {
    flex: 0 0 20%;
    border: none;
    background: none;
    box-shadow: none;
    padding: 0;
  }

  .accordion-button:not(.collapsed) {
    color: inherit;
    background-color: inherit;
  }

  .accordion-button::after {
    content: "\25BC"; /* Downward arrow */
    font-size: 1.2em;
    transform: rotate(0deg);
    transition: transform 0.2s;
  }

  .accordion-button:not(.collapsed)::after {
    transform: rotate(-180deg); /* Upward arrow */
  }

  .accordion-item {
    border-bottom: none;
  }

  .accordion-body {
    border-top: none;
  }

.onhover-category-list h4{
  font-size:14px;
}
.category-list li {
    display: block;
    width: 100%;
    height: fit-content;
}
.category-name {
  text-align: center;
  align-items: center;
  padding: 5px;
}
.category-list li a img {
  width: 65%;
  object-fit: contain;
}
header .header-nav .header-nav-left .category-dropdown .category-list {
  overflow-y: scroll;
  height: 500px;
}
header .header-nav .header-nav-left .category-dropdown {
  width: 400px;
}
header .onhover-category-list .onhover-category-box {
  width: 400px;
}
header .header-nav .header-nav-left .category-dropdown .category-list li a img {
  width: 12%;
  height: 12%;
  object-fit: contain;
}
header .onhover-category-list .onhover-category-box2 {
  position: absolute;
  top: 0;
  left: 100%;
  background: #fff;
  box-shadow: 0 0 8px #ddd;
  padding: 20px 25px;
  border-radius: 5px;
  width: 520px;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: auto auto;
  opacity: 0;
  visibility: hidden;
  margin-left: 14px;
  transition: all 0.3s ease-in-out;
}
.onhover-category-list .category-box-container {
  display: flex;
  justify-content: space-between;
}
.dropdown-item {
  color: black;
}
.dropdown .dropdown-menu {
  display: none;
  background: #fff;
  width: 340px;
}
.dropdown:hover > .dropdown-menu,
.dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.1em;
  margin-left: 0.1em;
}
@media screen and (min-width: 769px) {
  .dropend:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
  }
  .dropend .dropdown-toggle {
    margin-left: 0.5em;
  }
}

header .header-nav .header-nav-left .dropdown-category {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: calc(10px +(15 - 10)*((100vw - 320px) /(1920 - 320))) calc(16px +(31 - 16)*((100vw - 320px) /(1920 - 320)));
    background: var(--theme-3-color);
    color: #fff;
    border-radius: 5px;
    border: none;
}

@media screen and (max-width: 900px) {
  header .header-nav .header-nav-left .dropdown-category, .dropdown-menu{
      display:none;
  }

  .navbar-menu-button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc(10px +(15 - 10)*((100vw - 320px) /(1920 - 320))) calc(16px +(31 - 16)*((100vw - 320px) /(1920 - 320)));
    background: var(--theme-3-color);
    color: #fff;
    border-radius: 5px;
    border: none;
  }

    .flash-deal-offers[data-v-026bf9ca] {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
      align-items: center;
      background: linear-gradient(to bottom, var(--flashdeal-background-top-color), var(--flashdeal-background-bottom-color));
      border-radius: 5px;
      height: 472px;
  }
}
@media screen and (min-width: 900px) {
  #primaryCategoryMenu{
      display:none;
  }
}

</style>
