// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap';
import '../public/assets/css/Common.css';
// // import './assets/css/main.css';
// import './assets/css/vendors/bootstrap.css';
// import './assets/css/animate.min.css';
// import './assets/css/vendors/font-awesome.css';
// import './assets/css/vendors/feather-icon.css';
// import './assets/css/vendors/slick/slick.css';
// import './assets/css/vendors/slick/slick-theme.css';
// import './assets/css/bulk-style.css';
import '@fortawesome/fontawesome-free/css/all.css';




import { createApp } from 'vue';
import App from './App.vue';
import i18n from './i18n';
import router from './router/index';
import store from './store';
import axios from 'axios';
import baseUrlMixin from './baseUrlMixin';
import "vue-search-select/dist/VueSearchSelect.css"
import '@splidejs/vue-splide/css/skyblue';
import '@splidejs/vue-splide/css/sea-green';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Countdown from 'vue3-flip-countdown';
import VueLazyload from 'vue-lazyload';






import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import VueSplide from '@splidejs/vue-splide';

import vue3StarRatings from "vue3-star-ratings";

import feather from 'feather-icons';
import 'bootstrap-icons/font/bootstrap-icons.css';
feather.replace();

axios.defaults.baseURL = baseUrlMixin.computed.baseUrl() + 'api/v1/';
// const options = {
//     preLoad: 1.3, // Preload ratio
//     error: 'path/to/error-image.png', // Image when loading fails
//     loading: 'path/to/loading-image.gif', // Image during loading
//     attempt: 1, // Retry count when loading fails
//   };


const app = createApp(App)
app.component("vue3-star-ratings", vue3StarRatings);
app.mixin(baseUrlMixin);
app.use(router);
app.use(i18n);
app.use(store);
app.use(LoadingPlugin);
app.use( VueSplide );
app.use( Countdown  );
app.use(VueLazyload);
app.mount('#app');
