<template>
    <!-- <div class="dropdown theme-form-select"> 

        <button class="btn dropdown-toggle" type="button" id="select-dollar"
            data-bs-toggle="dropdown" aria-expanded="false">
            <span>USD</span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end sm-dropdown-menu"
            aria-labelledby="select-dollar">
            <li>
                <a class="dropdown-item" id="aud" href="javascript:void(0)">AUD</a>
            </li>
            <li>
                <a class="dropdown-item" id="eur" href="javascript:void(0)">EUR</a>
            </li>
            <li>
                <a class="dropdown-item" id="cny" href="javascript:void(0)">CNY</a>
            </li>
        </ul>
    </div> -->

    <select  v-model="currency_code" class="theme-form-select" @change="updateCurrency" aria-label="Currency Select">
        <option v-for="(locale, i) in CurrenciesData" :key="`locale-${i}`" :value="locale" style="color: #645b5b;">
                    <span>{{ getCurrencyFullName(locale) }}</span>
            
        </option>
    </select>
</template>
<script>
// import { useStore } from 'vuex';
import Cookies from 'js-cookie';
import router from '../router/index';
import axios from 'axios';
export default {
    name: "currency-selector",
    data() {
        return {
             locales: ["$", "Rs","€"],
             CurrenciesData: [],   
        cookieCurrency:'',
        currency_code:'',
        defaulturrencyCode:'USD',
        
       // $i18n.locale: this.LoginUserLanguageCode

     };
      
    },
     computed: {
   

    },
     watch: {
        cookieCurrency(newValue){
            // this.setItemWithExpiration('CookiesCurrencyCode', newValue, this.expDay);
            Cookies.set("CookiesCurrencyCode", newValue, { expires: 6 });
            this.$emit('selected-currency-event', newValue);
            router.push('/');
            window.location.reload();         
        }
    },
    methods: {
        updateCurrency(){
            this.cookieCurrency = this.currency_code;
                // Store the selected language in a cookie with a 6-day expiratio         
           
        },
        
        getCurrencyFullName(locale) {
            // Add logic here to return the full name based on the locale
            switch (locale) {
                case "USD":
                    return "USD (US Dollar)";
                case "EUR":
                    return "EUR (EURO)";
                case "AUD":
                    return "AUD (Australian Dollar)";
                case "NZD":
                    return "NZD (New Zealand Dollar)";
                case "INR":
                    return "INR (Indian Rupee)";
                case "GBP":
                    return "GBP (British Pound)";
                    case "JPY":
                    return "JPY (Japanese Yen)";
                case "LKR":
                    return "LKR (Sri Lankan Rupee)";
                case "SGD":
                    return "SGD (Singapore Dollar)";
                    case "CAD":
                    return "CAD (Canadian Dollar)";
                case "AED":
                    return "AED (AED Dirham)";
                default:
                    return "";
            }
        },
         async fetchCurrenciesdata() {
            try {
                const response = await axios.get('Currency/list'); // Replace with your API endpoint
                this.CurrenciesData = response.data;
                this.setItemWithExpirationCommon('CookieCurrenciesData', this.CurrenciesData, this.expDay);
               // Cookies.set('CookieCurrenciesData', JSON.stringify(this.CurrenciesData), { expires: this.expDay });
              // console.log('currency',this.CurrenciesData);
            } catch (error) {
                console.error('Error fetching currencies data:', error);
            }
        },

        async fetchDefaultCurrenciesdata() {
            try {
                const response = await axios.get('defalutCurrency/list'); // Replace with your API endpoint
                this.defaulturrencyCode = response.data;
                this.currency_code = response.data;
                console.log();
                Cookies.set("CookiesCurrencyCode", this.currency_code, { expires: 6 });
              //  this.setItemWithExpiration('CookiesCurrencyCode', response.data, this.expDay);
                
                this.$emit('selected-currency-event', response.data);
              //  console.log('currency',this.defaulturrencyCode);
            } catch (error) {
                console.error('Error fetching currencies data:', error);
            }
        },
       
    },
     mounted() {
      // console.log('cookieLanguage',Cookies.get("CookiesLanguageCode"))
      var currency_code_storage = Cookies.get("CookiesCurrencyCode");
       if(currency_code_storage){
            this.currency_code = currency_code_storage; 
       }else{
            this.fetchDefaultCurrenciesdata();
            this.currency_code = this.defaulturrencyCode;
            Cookies.set("CookiesCurrencyCode", this.currency_code, { expires: 6 });
            //this.setItemWithExpiration('CookiesCurrencyCode', this.currency_code, this.expDay);
           
       }
       var currencies_data_storge = this.getItemWithExpirationCommon('CookieCurrenciesData');

       if(currencies_data_storge){
            this.CurrenciesData = currencies_data_storge;
            
        }else{
            this.fetchCurrenciesdata();         
        }
      
    }
};
</script>

<style scoped>
.theme-form-select span{
    color:Black;
}
</style>